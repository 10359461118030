/*
 * Wording Info:
 * currentCart = zwischengespeicherter Warenkorb in der variable currentCart
 * Basket = Html-Objekt, das auf der Webseite zu sehen ist
 */
import { DummyImageSize } from '../Shared/Models/DummyImageSizeEnum';
import ProductHelper = require('../Shared/ProductHelper');
import { CheckoutService } from './checkoutService';
import { TrackMiniBasket } from './Tracking/checkoutTrackingGlobal';
import { IBasketOverlayModel } from './Models/BasketOverlay/IBasketOverlayModel';
const checkoutService = new CheckoutService();
import { onChangeQuantityTracking } from './Tracking/checkoutTrackingStep1';
import ICheckoutflowResponse = CheckoutflowReponse.ICheckoutflowResponse;
import ICart = CheckoutflowReponse.ICart;
import IPrice = CheckoutflowReponse.IPrice;
import ILineItem = CheckoutflowReponse.ILineItem;
import IGiftLineItem = CheckoutflowReponse.IGiftLineItem;
import ILineItemChange = CheckoutflowRequest.ILineItemChange;
var basketPrototypes = $('#BasketPrototypes') as JQuery;
var basketContainer = $('#basket_container') as JQuery;
export var currentCart: ICart;

/**
 * Füllt den basket_container mit den Daten aus der übergebenen Cart.
 * @param cart
 * @returns {}
 */
export async function fillBasket(cart: ICart) {
    let currentItemList = basketContainer.find('#BasketItemList');
    if (cart && cart.lineItems && cart.lineItems.length > 0) {
        let basketItemListClone = currentItemList.clone();
        basketItemListClone.html('');

        for (let lineItem of cart.lineItems.reverse()) {
            await addLineItemToBasket(lineItem, basketItemListClone);
        }

        if (cart.giftLineItems && cart.giftLineItems.length > 0) {
            for (let giftLineItem of cart.giftLineItems.reverse()) {
                addGiftLineItemToBasket(giftLineItem, basketItemListClone);
            }
        }

        currentItemList.replaceWith(basketItemListClone);
    } else {
        currentItemList.html('');
    }
    currentCart = cart;
    basketContainer.find('.approximatedPriceNote').addClass('hide');
    refreshTotalCartPrice();
    basketContainer.addClass('hide');
    hideOrShowElementsBasedOnNewCart();
}

async function addGiftLineItemToBasket(lineItem: IGiftLineItem, basketItemListClone: JQuery) {
    let basketItem = $('#BasketPrototypes')
        .find('.basket_lineitem')
        .clone();
    basketItemListClone.append(basketItem);
    await fillProductContainer(basketItem, lineItem, true);
}

async function addLineItemToBasket(lineItem: ILineItem, basketItemListClone: JQuery) {
    let basketItem = $('#BasketPrototypes')
        .find('.basket_lineitem')
        .clone();
    basketItemListClone.append(basketItem);
    await fillProductContainer(basketItem, lineItem, false);
}

/**
 * Füllt das übergebene BasketItem mit den Daten aus dem übergebenen LineItem und blendet es dannach ein
 * @param basketItem leerer ausgeblendeter (über die Klasse "hide") - aber bereits der Produktliste angehängter - Produktcontainer
 * @param lineItem das im Warenkorb liegende LineItem
 * @param gift Flag das anzeigt, ob es sich um ein Werbemittel handelt
 */
async function fillProductContainer(basketItem: JQuery, lineItem: ILineItem, gift: boolean) {
    let product = lineItem.product;
    let productId = product.productId;

    //basketItem
    basketItem.attr('id', 'basket_lineitem_' + lineItem.id);
    basketItem.attr('data-lineitemid', lineItem.id);

    //productdetails
    basketItem.find('.lineitem_productlink').attr('href', '/' + product.seoOptimizedUrlPart + '/product/' + productId);
    if (product.hasProduktabbildung) {
        let image = basketItem.find('.lineitem_productimage');
        image.attr('data-src', await ProductHelper.getImagePath(productId, 100));
    } else {
        basketItem
            .find('.lineitem_productimage')
            .attr('data-src', await ProductHelper.getDummyImage(DummyImageSize.large));
    }
    basketItem.find('.lineitem_title').html(product.titleMainHead ? product.titleMainHead : product.titleMain);

    if (!gift) {
        basketItem.find('.lineitem_authorlist').html(product.authorList);
        if (product.isbnIssn) {
            basketItem.find('.lineitem_isbn').html('ISBN ' + product.isbnIssn);
        } else {
            basketItem.find('.lineitem_isbn').addClass('hide');
        }
        basketItem.find('.lineitem_edition').html(product.edition);
        if (product.publicationYear > 0) {
            basketItem.find('.lineitem_publicationyear').html(product.publicationYear + '');
        }
        basketItem.find('.lineitem_mediatype').html(product.mediaType.mediaTypeName);
    } else {
        basketItem.find('.isbn-webcode.lineitem_isbn').remove();
        basketItem.find('.counter-elements.ajax_checkout_counter').remove();
    }

    if (product.publisher && product.publisher.publisherName) {
        basketItem.find('.lineitem_publisher').html(product.publisher.publisherName);
    }

    basketItem.find('.lineitem_totalprice').html(
        (lineItem.totalItemGrossPrice.amount / 100).toLocaleString('de-De', {
            style: 'currency',
            currency: lineItem.totalItemGrossPrice.currency.currencyIsoCode,
        })
    );

    basketItem
        .find('.lineitem_count')
        .val(lineItem.quantity + '')
        .html(lineItem.quantity + '');

    let price = product.prices.filter(
        x => x.priceType.priceTypeId == lineItem.currentPriceType.priceTypeId
    )[0] as IPrice;
    basketItem
        .find('.lineitem_pricetypedescription')
        .html(
            price.priceDescriptionShort ? price.priceDescriptionShort : lineItem.currentPriceType.priceTypeDescription
        );

    if (price.isApproximatePrice) {
        basketItem.find('.lineitem_caInfo').removeClass('hide');
    }

    // mediaTypeId == 213 entspricht e-Learning
    if (product.canNotBeReturned || product.mediaType.mediaTypeId == 213) {
        basketItem.find('.LineItem_CanNotBeReturnedLabel').removeClass('hide');
    }
    //Ebook oder kostenloses Produkt
    if (!gift && (product.mediaType.mediaTypeId == 82 || lineItem.totalItemGrossPrice.amount === 0)) {
        basketItem.find('.ajax_checkout_counter').addClass('disabled-Element');
    }
    if (!ProductHelper.checkAvailabilityStatus(product.availabilityStatusId)) {
        basketItem.find('.LineItem_CanNotBeOrderedLabel').removeClass('hide');
    } else {
        let status = await ProductHelper.getAvailabilityStatus(product.availabilityStatusId);
        if (
            product.availabilityStatusId == 3 &&
            product.dateOfPublicationDescription != null &&
            product.dateOfPublicationDescription != ''
        ) {
            basketItem
                .find('.lineitem_availability')
                .html(
                    (await ProductHelper.getAvailabilityStatus(product.availabilityStatusId)) +
                        ' (' +
                        product.dateOfPublicationDescription +
                        ')'
                );
        } else {
            basketItem
                .find('.lineitem_availability')
                .html(await ProductHelper.getAvailabilityStatus(product.availabilityStatusId));
            if (product.availabilityStatusId == 1 || product.availabilityStatusId == 4) {
                basketItem.find('.lineitem_availability').addClass('availabilityStatusColorGreen');
                basketItem.find('.lineitem_availability_img').removeClass('hide');
            }
        }
    }
    if (product.mediaType.mediaTypeId == 213) {
        basketItem.find('.price_container').addClass('service');
    }
    if (product.mediaType.mediaGroupName && product.mediaType.mediaGroupName === 'eBook') {
        let drmInfo = await ProductHelper.getDRMInfo(product.dRMId);
        if (drmInfo) {
            let drmInfoLinks = ` (${product.bindingType} ${drmInfo})`;
            let drmInfoRechts = `${product.bindingType} (${drmInfo})`;
            basketItem
                .find('.lineitem_kopierschutzInfo_details')
                .html(drmInfoLinks)
                .removeClass('hide');
            basketItem
                .find('.lineitem_kopierschutzInfo_price')
                .html(drmInfoRechts)
                .removeClass('hide');
        }
        let systemanforderungenLink = await ProductHelper.getSystemanforderungenLink();
        basketItem
            .find('.lineitem_systemvoraussetzungenLink')
            .removeClass('hide')
            .find('a')
            .html(systemanforderungenLink.displayName)
            .attr('href', systemanforderungenLink.href)
            .attr('target', systemanforderungenLink.target);
        basketItem.find('.LineItem_isEbook').removeClass('hide');
        basketItem.find('.checkoutServiceIcons').removeClass('hide');
        basketItem.find('.price_container').addClass('service');
    }
    if (
        product.prices.length == 1 &&
        (product.deliveryTypeId == 5 || product.deliveryTypeId == 4) &&
        !(lineItem.currentPriceType.priceTypeId.toString()[0] === '3')
    ) {
        //Produktvarianten vorhanden
        basketItem.find('.aktualisierungsService_aendern_Button').removeClass('hide');
        basketItem.find('.price_container').addClass('service');
        if (lineItem.chosenDeliveryType == 2) {
            basketItem.find('.lineitem_aktualisierungsservice').removeClass('hide');
        }
        if (lineItem.chosenDeliveryType == 1) {
            basketItem.find('.lineitem_einzelbezug').removeClass('hide');
        }
    }
    if (product.deliveryTypeId == 2) {
        basketItem.find('.LineItem_AbonnementHinweistext').removeClass('hide');
    }
    basketItem
        .find('.lineitem_pricedescription')
        .html(
            product.prices.filter(x => x.priceType.priceTypeId == lineItem.currentPriceType.priceTypeId)[0].description
        )
        .removeClass('hide');

    // In der Pricedesription soll der Link auf die Info Seite für den Aktualisierungsservice entfernt werden.
    // Ticket ECOM-459
    let priceDescriptionLink = basketItem.find('.lineitem_pricedescription').find('a.internal') as JQuery<
        HTMLAnchorElement
    >;
    if (
        priceDescriptionLink &&
        priceDescriptionLink.length &&
        priceDescriptionLink[0].href === 'https://www.beck-shop.de/content/loseblattwerke/aktualisierungsservice/7841/'
    ) {
        priceDescriptionLink.remove();
    }

    if (product.deliveryTypeId == 3 || product.deliveryTypeId == 6) {
        let additionalPriceInfo = await ProductHelper.getAdditionalPriceInfoByDelivertypeId(product.deliveryTypeId);

        if (additionalPriceInfo) {
            basketItem
                .find('.lineitem_additionalPriceInfo')
                .html(additionalPriceInfo)
                .removeClass('hide');
        }
    }

    basketItem.find('.lineitem_unitprice').html(
        (lineItem.singleItemGrossPrice.amount / 100).toLocaleString('de-De', {
            style: 'currency',
            currency: lineItem.singleItemGrossPrice.currency.currencyIsoCode,
        })
    );

    if (isPriceApproximated(lineItem)) {
        basketItem.find('.lineitem_unitprice').prepend('ca. ');
        basketItem.find('.lineitem_totalprice').prepend('ca. ');
    }

    //Button-logik
    if (product.prices.length > 1) {
        //Produktvarianten vorhanden
        basketItem.find('.produktvariante_aendern_button').removeClass('hide');
        basketItem.find('.price_container').addClass('service');
    }

    basketItem.find('.delete-item').attr('data-productid', productId);

    if ($("#loggedInFlag").length > 0) {
        basketItem.find('.checkout-remember').attr('data-id', productId);    
    }
    else {
        basketItem.find('.checkout-remember').remove();
    }
    
    basketItem.removeClass('hide');
    loadImage(basketItem);
}

export async function loadImage(basketItem) {
    let allImageWrapper = $(basketItem).find('.imgWrapper');
    allImageWrapper.each((index, element) => {
        let imageWidth = $(element).width();
        let image = $(element).find('.lineitem_productimage');
        image.attr('src', `${image.attr('data-src')}&width=${Math.round(imageWidth)}`);
    });
}

/**
 * Überprüft ob ein Preis des LineItems ein Circa-Preis ist
 * @param lineitem
 */
function isPriceApproximated(lineitem: ILineItem): boolean {
    return lineitem.product.prices.some(x => x.isApproximatePrice);
}

/**
 * füllt das update-Model mit den aktuellen Daten. Das was geändert werden soll wird in der entsprechenden Methode angepasst.
 * @param lineitem
 */
function buildLineItemChange(lineitem: ILineItem): ILineItemChange {
    let lineItemChange = {} as ILineItemChange;
    lineItemChange.productId = lineitem.product.productId;
    lineItemChange.lineItemId = lineitem.id;
    lineItemChange.quantity = lineitem.quantity;
    return lineItemChange;
}

/*
 * Setzt den Gesamtpreis des Baskets auf den aktuellen Wert aus der currentCart.
 * Wenn es sich um einen Ca.-Preis handelt wird "Preis auf Anfrage" zusammen mit einer Erläuterung ausgegeben
 */
function refreshTotalCartPrice() {
    if (currentCart) {
        if (currentCart.areTotalPricesApproximated) {
            $('#basket_container')
                .find('.basket_totalprice')
                .html('Preis auf Anfrage');
            $('#basket_container')
                .find('.approximatedPriceNote')
                .removeClass('hide');
        } else {
            if (currentCart.totalGrossPrice.amount != undefined) {
                $('#basket_container')
                    .find('.basket_totalprice')
                    .html(
                        (currentCart.totalGrossPrice.amount / 100).toLocaleString('de-De', {
                            style: 'currency',
                            currency: currentCart.totalGrossPrice.currency.currencyIsoCode,
                        })
                    );
            }
        }
    }
}

/*
 * Zeigt Elemente an oder blendet Sie aus je nachdem ob die currentCart befüllt ist oder nicht
 */
function hideOrShowElementsBasedOnNewCart() {
    if (currentCart && currentCart.lineItems.length > 0) {
        $('#basket_container').removeClass('hide');
        $('.onlyShowInFilledBasket').removeClass('hide');
        $('.showInEmptyBasket').addClass('hide');
        $('.disableInEmptyBasket').removeClass('disabled-Element');
    } else {
        $('.onlyShowInFilledBasket').addClass('hide');
        $('.showInEmptyBasket').removeClass('hide');
        $('.disableInEmptyBasket').addClass('disabled-Element');
    }
}

export function initEvents() {
    //Zieht dem Item-Quantity 1 ab und triggert das Change Event des input-Fields
    $(basketContainer).on('click', '.ajax_checkout_counter .counter-subtr', function() {
        let counter = $(this).next('.lineitem_count');
        let currentvalue = +counter.val();
        if (currentvalue > 1) {
            counter.val(currentvalue - 1);
            counter.trigger('change');
        } else {
            deleteLineItemFromBasket($(this));
        }
    });

    //Addiert 1 zu der Item-Quantity und triggert das Change Event des input-Fields
    $(basketContainer).on('click', '.ajax_checkout_counter .counter-add', function() {
        let counter = $(this).prev('.lineitem_count');
        let currentvalue = +counter.val();
        counter.val(currentvalue + 1);
        counter.trigger('change');
    });

    //Updatet die LineItem-Quantity auf den Wert des Input-Fields
    $(basketContainer).on('change', '.lineitem_count', function() {
        if ($(this).val() < 1) {
            $(this).val(1);
        } else {
            setNewQuantityOfBasketItem($(this));
        }
    });

    //Disabled den löschen-Button und löscht dann das LineItem
    $(basketContainer).on('click', '.ajax_checkout_delete_from_basket', function() {
        $(this).addClass('disabled-Element');
        deleteLineItemFromBasket($(this));
    });

    //Zeigt das Aktualisierungs-Service-Modal an
    $(basketContainer).on('click', '.changeServiceTrigger', function() {
        let modalId = $(this).attr('data-target');
        showModal($(modalId));
    });

    //Füllt das Modal aus dem geklickten LineItem und zeigt es an
    $(basketContainer).on('click', '.produktvariante_aendern_button', function() {
        let modalId = $(this).attr('data-target');
        let modal = $(modalId);
        let lineItemId = $(this)
            .parents('.basket_lineitem')
            .attr('data-lineitemid') as string;
        let currentLineItem = getLineItemById(lineItemId);
        fillProduktVariantenModal(modal, currentLineItem);
    });

    //Füllt das Modal aus dem geklickten LineItem und zeigt es an
    $(basketContainer).on('click', '.aktualisierungsService_aendern_Button', function() {
        let modalId = $(this).attr('data-target');
        let modal = $(modalId);
        modal.find('.disabled-Element').removeClass('disabled-Element');
        modal.find('input[type=radio]').removeAttr('checked');
        let lineItemId = $(this)
            .parents('.basket_lineitem')
            .attr('data-lineitemid') as string;
        let currentLineItem = getLineItemById(lineItemId);
        fillBezugsartenModal(modal, currentLineItem);
        showModal(modal);
    });

    /**
     * Aktualisiert den Pricetype des aktuellen LineItems mit dem neu ausgewählten Pricetype
     */
    $(basketContainer).on('click', '.refresh-basket-btn_produktvariante', function() {
        setNewPricetypeIdOfBasketItem($(this));
    });

    //Aktualisiert den ChosenDeliveryType des aktuellen LineItems mit dem neu ausgewählten ChosenDeliveryType
    $('.refresh-basket-btn_bezugsarten').on('click', function() {
        setNewChosenDeliveryTypeOfBasketItem($(this));
    });

    $('#produktvariantenModal').on('click', '.show-more-button', function() {
        if ($(this).prev().hasClass('snippet')) {
            $(this).html('Weniger anzeigen');
        }
        else {
            $(this).html('Mehr anzeigen');
        }
        $(this).prev().toggleClass('snippet');
    });
}

//Updatetet die Quantity des LineItems
function setNewQuantityOfBasketItem(countElement: JQuery) {
    let basketitem = countElement.parents('.basket_lineitem');
    basketitem.find('.ajax_checkout_counter').addClass('disabled-Element');
    let quantity = countElement.val() as number;
    let lineItemId = basketitem.attr('data-lineitemid') as string;
    let lineItem = getLineItemById(lineItemId);
    updateQuantityOfLineItem(lineItem, quantity)
        .catch()
        .then(() => {});
}

function setNewPricetypeIdOfBasketItem(button: JQuery) {
    button.addClass('disabled-Element');
    let parentContainer = button.parents('.produktvarianten_modal_content');
    let lineItemId = parentContainer.attr('data-lineitemid') as string;
    let newPricetypeId = parentContainer.find('.produktvariante_radio:checked').val() as number;
    let lineItemToUpdate = getLineItemById(lineItemId);
    updatePriceTypeOfLineItem(lineItemToUpdate, newPricetypeId)
        .then(_ => {
            hideModal(parentContainer.parents('#produktvariantenModal'));
        })
        .catch()
        .then(_ => {
            button.removeClass('disabled-Element');
        });
}

function setNewChosenDeliveryTypeOfBasketItem(button: JQuery) {
    button.addClass('disabled-Element');
    let parentContainer = button.parents('.bezugsarten_modal_content');
    let lineItemId = parentContainer.attr('data-lineitemid') as string;
    let newChosenDeliveryType = +parentContainer.find('.chosenDeliveryType_radio:checked').val() as number;
    let lineItemToUpdate = getLineItemById(lineItemId);
    updateChosenDeliveryTypeOfLineItem(lineItemToUpdate, newChosenDeliveryType)
        .then(_ => {
            hideModal(button.parents('#aktualisierungsServiceModal'));
        })
        .catch()
        .then(_ => {
            button.removeClass('disabled-Element');
        });
}

async function updateQuantityOfLineItem(lineItemToUpdate: ILineItem, quantity: number) {
    let lineItemChange = buildLineItemChange(lineItemToUpdate);
    let oldQuantitiy = lineItemToUpdate.quantity;
    lineItemChange.quantity = quantity;
    const cart = await updateLineItem(lineItemChange);
    var updatedLineItem = getLineItemById(lineItemToUpdate.id);
    await getItemCountForBasket();
    onChangeQuantityTracking(currentCart, updatedLineItem, quantity, oldQuantitiy);
}

async function updatePriceTypeOfLineItem(lineItemToUpdate: ILineItem, pricetypeId: number) {
    let lineItemChange = buildLineItemChange(lineItemToUpdate);
    lineItemChange.priceTypeId = pricetypeId;
    await updateLineItem(lineItemChange);
}

async function updateChosenDeliveryTypeOfLineItem(lineItemToUpdate: ILineItem, chosenDeliveryType: number) {
    let lineItemChange = buildLineItemChange(lineItemToUpdate);
    lineItemChange.chosenDeliveryType = chosenDeliveryType;
    lineItemChange.priceTypeId = lineItemToUpdate.currentPriceType.priceTypeId;
    await updateLineItem(lineItemChange);
}

async function updateLineItem(lineItemChange: ILineItemChange) {
    try {
        const newCart = await checkoutService.updateLineItem(lineItemChange);
        fillBasket(newCart);
        return newCart;
    } catch {}
}

/**
 * Füllt das Produktvarianten-Modal mit den Preisen aus dem übergebenen LineItem
 * @param modal
 * @param lineItem
 */
function fillProduktVariantenModal(modal: JQuery, currentLineItem: ILineItem) {
    let produktvariantenContainer = modal.find('.produktvariantenContainer');
    produktvariantenContainer.parents('.produktvarianten_modal_content').attr('data-lineitemid', currentLineItem.id);
    produktvariantenContainer.html('');
    let checkItemPrototype = $('#produktvariantePrototypes .check-item');
    for (let price of currentLineItem.product.prices) {
        let pricetypeId = price.priceType.priceTypeId;
        let newCheckItem = checkItemPrototype.clone();
        newCheckItem
            .find('input[type=radio]')
            .val(pricetypeId)
            .attr('id', 'produktvariante_checkbox_' + pricetypeId)
            .next('label')
            .attr('for', 'produktvariante_checkbox_' + pricetypeId);
        let priceAmount = (price.priceGross / 100).toLocaleString('de-De', {
            style: 'currency',
            currency: price.currency.currencyIsoCode,
        });
        newCheckItem
            .find('.produktvariante_head')
            .html(
                price.priceDescriptionShort
                    ? price.priceDescriptionShort + ' ' + priceAmount
                    : price.priceType.priceTypeDescription + ' ' + priceAmount
            )
            .attr('for', 'produktvariante_checkbox_' + pricetypeId);
        newCheckItem.find('.produktvariante_description').html(price.description);
        newCheckItem.find('.produktvariante_specialConditions').html(price.specialConditions);
        
        produktvariantenContainer.append(newCheckItem);
    }

    //Checked die aktuelle Produktvariante
    produktvariantenContainer
        .find('#produktvariante_checkbox_' + currentLineItem.currentPriceType.priceTypeId)
        .prop('checked', true);

    showModal(modal);
    showShowMoreButton();
}

function showShowMoreButton() {
    $(".mobile-description").each(function() {
        let element = $(this);
        if (element.height() > 100) {
            element.addClass('snippet');
        }
        else {
            element.siblings('.show-more-button').remove();
        }
    });
    
}

/**
 * Füllt das Bezugsarten-Modal mit den chosenDeliveryType aus dem übergebenen LineItem
 * @param modal
 * @param lineItem
 */
function fillBezugsartenModal(modal: JQuery, lineItem: ILineItem) {
    let container = modal.find('.bezugsartenContainer');
    container.find('.chosenDeliveryType_radio').prop('checked', false);
    if (lineItem.product.deliveryTypeId == 4) {
        container.find('.description_optionalefortsetzung').removeClass('hide');
        container.find('.description_standardfortsetzung').addClass('hide');
    }
    if (lineItem.product.deliveryTypeId == 5) {
        container.find('.description_standardfortsetzung').removeClass('hide');
        container.find('.description_optionalefortsetzung').addClass('hide');
    }
    container.parents('.bezugsarten_modal_content').attr('data-lineitemid', lineItem.id);
    let checkItems = container.find('.check-item');
    checkItems.each(function() {
        let checkitem = $(this);
        if (checkitem.find('input[name=bezugsart]').val() == lineItem.chosenDeliveryType) {
            checkitem.find('input[name=bezugsart]').prop('checked', true);
            checkitem.addClass('disabled-Element');
        } else {
            checkitem.removeClass('disabled-Element');
        }
    });
}

//Löscht das LineItem aus dem Warenkorb
async function deleteLineItemFromBasket(deleteElement: JQuery) {
    let basketitem = deleteElement.parents('.basket_lineitem');
    let lineItemId = basketitem.attr('data-lineitemid') as string;
    let lineItemToDelete = getLineItemById(lineItemId);
    let oldQuantity = lineItemToDelete.quantity;
    let newQuantity = 0;
    let lineItemChange = buildLineItemChange(lineItemToDelete);
    await deleteLineItem(lineItemChange);
    lineItemToDelete.quantity = 0;
    let deletedLineItem = lineItemToDelete;
    await getItemCountForBasket(true);
    onChangeQuantityTracking(currentCart, deletedLineItem, newQuantity, oldQuantity);
}

async function deleteLineItem(lineItemChange: ILineItemChange) {
    const newCart = await checkoutService.deleteLineItem(lineItemChange);
    fillBasket(newCart);
}

function getLineItemById(lineItemId: string) {
    var lineItem = currentCart.lineItems.filter(x => x.id == lineItemId)[0];
    if (lineItem) {
        return lineItem;
    }
    return currentCart.giftLineItems.filter(x => x.id == lineItemId)[0];
}

/*
 * Ruft die Anzahl der Produkte im Warenkorb ab.
 */
export async function getItemCountForBasket(overrideEmptyBasket: boolean = false) {
    try {
        const cart = await checkoutService.getItemCountInBasket();
        if ((cart && cart.itemCount) || overrideEmptyBasket) {
            setItemCountForBasket(cart?.itemCount ?? 0);
            TrackMiniBasket(cart); 
        }
        if (cart && cart.items) {
            fillBasketOverlay(cart);
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Zeigt die Anzahl der Produkte im Warenkorb an.
 * @param itemCount
 */
function setItemCountForBasket(itemCount: number) {
    if (itemCount > 0) {
        $('.headerBasket.notify')
            .html(itemCount + '')
            .removeClass('hide');
    }
    if (itemCount == 0) {
        $('.headerBasket.notify')
            .html('')
            .addClass('hide');
    }
}

function showModal(modal: JQuery) {
    $('body').addClass('modal-open');
    $('#modal-bg-layover').show();
    modal.show();
}

function hideModal(modal: JQuery) {
    $('body').removeClass('modal-open');
    $('#modal-bg-layover').hide();
    modal.hide();
}

function fillBasketOverlay(cart: IBasketOverlayModel) {
    const basketOverlay = $('.basket-menu-bubble');

    basketOverlay.find('.basket-summary .pricebox .price').html(cart.totalGross);

    basketOverlay.find('.item.added').remove();

    for (let item of cart.items) {
        let itemPrototype = basketOverlay.find('.item.prototype').clone();
        itemPrototype
            .removeClass('prototype')
            .removeClass('hidden')
            .addClass('added');
        itemPrototype.find('.author').html(item.authorList);
        itemPrototype.find('.title').html(item.title);
        itemPrototype.find('.edition').html(item.edition);

        itemPrototype.find('.availability').html(item.availabilityStatus);
        if (item.availabilityStatusId !== 1) {
            itemPrototype.find('.availability').removeClass('available');
        }

        itemPrototype.find('.amount span').html(item.quantity.toString());
        itemPrototype.find('img').attr('src', item.imageUrl);
        itemPrototype.find('a').attr('href', `/${item.seoPath}/product/${item.productId}`);
        basketOverlay.find('.basket-items').append(itemPrototype);

        itemPrototype
            .find('.singleprice span')
            .html((item.singleItemPrice / 100).toLocaleString('de-De', { style: 'currency', currency: 'EUR' }));
        itemPrototype
            .find('.totalprice span')
            .html((item.totalItemPrice / 100).toLocaleString('de-De', { style: 'currency', currency: 'EUR' }));
    }
}
