//declare var hallo: any;
import { mobileFunctions, setDummyImage, DelocaleString, cutTextWholeWords } from '../../../scripts/functions.js';
import { checkoutAdd } from '../../Checkout/checkoutGlobal';
import ProductHelper = require('../../Shared/ProductHelper');

var lastClickedFilterParentId = '';
$(() => {
    $('.ajax_filter_link_tag').on('click', function() {
        clickHandlerAjaxSearch(this);
    });

    $('.ajax_filter_link').on('click', function(e) {
        e.preventDefault();
        clickHandlerAjaxSearch(this);
    });
    $('.filterCategory').on('click', function() {
        if ($(this).hasClass('ffBoolFilterLink')) {
            lastClickedFilterParentId = '';
        } else {
            lastClickedFilterParentId = $(this).attr('id');
        }
    });
    $('.ajax_sort_select').on('change', function() {
        clickHandlerAjaxSearch(this);
    });

    $('.eraseSearchTerm').on('click', function(e) {
        e.preventDefault();
        $(this).addClass('hide');
        var fieldId = $(this).data('searchfieldid');
        (<HTMLInputElement>document.getElementById(fieldId)).value = '';
    });

    $('body').on('input', '.ff-filter-input', function() {
        let filterValue = $(this).val() as string;
        let siblings = $(this)
            .parent()
            .siblings('li');
        siblings.show();
        siblings.each(function() {
            let value = $(this).data('filtervalue');
            if (
                String(value)
                    .toLowerCase()
                    .indexOf(filterValue.toLowerCase()) === -1
            ) {
                $(this).hide();
            }
        });
    });
});

export function setFilter(requestUrl) {
    $.ajax({
        method: 'POST',
        dataType: 'JSON',
        contentType: 'application/json',
        data: JSON.stringify({ filterUrl: requestUrl, query: '' }),
        url: '/Umbraco/api/sucheapi/SucheJson',
        success: data => {
            $('.ajax_search_results').html('');
            var result = data.searchResult;
            if ($('.ajax_filter_menu').find('.categorie.top').length !== 0) {
                $('.ajax_filter_menu>li')
                    .slice(1)
                    .remove();
            } else {
                $('.ajax_filter_menu').html('');
            }
            $('.ajax_filter_menu_mobile').html('');
            $('.ajax_pagination').html('');
            $('.mobile-filter-tags').html('');
            $('.ajax_sort_select').html('');

            //der aktuelle Filter wird in die URL gebaut und diese als die aktuelle in die Brower History gepushed
            history.pushState(null, null, `?filter=${window.btoa(requestUrl)}`);

            setResultCountFor(
                result.resultCount,
                result.breadCrumbTrailItems[0] ? result.breadCrumbTrailItems[0].Value : null
            );

            createFilterGroup(result.groups);
            createFilterGroupMobile(result.groups);
            createSearchResults(result, result.records);
            createFilterTags(result.groups);
            createSortList(result.sortsList);
            if (result.searchParams.indexOf('query') == -1) {
                if (result.paging.currentPage != 1) {
                    $('link[rel="canonical"]').remove();

                    if ($('meta[name="robots"]').length > 0) {
                        $('meta[name="robots"]').attr('content', 'noindex, follow');
                    } else {
                        var meta = document.createElement('meta');
                        meta.name = 'robots';
                        meta.content = 'noindex, follow';
                        document.head.appendChild(meta);
                    }
                } else {
                    $('meta[name="robots"]').remove();
                    if ($('link[rel="canonical"]').length > 0) {
                        $('link[rel="canonical"]').attr('href', window.location.origin + window.location.pathname);
                    } else {
                        var link = document.createElement('link');
                        link.rel = 'canonical';
                        link.href = window.location.origin + window.location.pathname;
                        document.head.appendChild(link);
                    }
                }
            }
            if (result.resultCount > 20) {
                createPagination(result.paging);
            }
            $('.popover-modal').hide(0);
            $('body').removeClass('noScroll');
            mobileFunctions();
            //Klappt das Menu des zuletzt geklickten filters wieder auf
            if (lastClickedFilterParentId !== '') {
                $('#' + lastClickedFilterParentId).click();
            }
            $('.spinner-wrapper').hide();
            cutTextWholeWords();
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        },
        error: function() {
            $('.spinner-wrapper').hide();
        },
    });
}
function clickHandlerAjaxSearch(e) {
    $('.spinner-wrapper').show();
    var requestUrl = $(e).is('select')
        ? $(e)
              .find(':selected')
              .data('url')
        : $(e).data('url');
    setFilter(requestUrl);
}

function setResultCountFor(resultCount, breadCrumbTrailItem) {
    var searchQuery = nullOrEmpty(breadCrumbTrailItem) ? '' : breadCrumbTrailItem;
    if (
        $('.resultCountFor')
            .next('input[type=hidden]')
            .val() !== 'noSearchQuery' &&
        searchQuery != ''
    ) {
        $('.resultCountFor').html(resultCount + ' Treffer für "' + searchQuery + '"');
    } else {
        $('.resultCountFor').html(resultCount + ' Treffer');
    }
}

function createFilterGroup(filterGroups) {
    filterGroups.forEach((value, key) => {
        if (!nullOrEmpty(value.elements) && value.elements.filter(x => x.name.toLowerCase() === 'true')[0] != null) {
            createBoolFilter(value);
        } else {
            var prototype = $('#protoypes #ajax_filter_element').clone();
            prototype.find('.txt').html(value.name);
            var filterHolder = prototype.find('.categorySubMenu');
            if (key > 3) {
                prototype.find('.expander').addClass('fa-angle-down');
            } else {
                filterHolder.addClass('active');
                prototype.find('.expander').addClass('fa-angle-up');
            }

            if (value.elements.length > 7) {
                filterHolder.addClass('katNaviScroll');
            }
            if (key > 3 && value.elements.length < 7) {
                filterHolder.css('display', 'none');
            }
            if (value.name !== 'Fachbereich' && value.name !== 'Lieferbarkeit' && value.name !== 'Preis') {
                let txtInput = $('#protoypes #ajax_filter_textinput').clone();
                $(txtInput).data('filtername', value.name);
                txtInput.removeAttr('id');
                filterHolder.append(txtInput);
            }
            value.selectedElements.forEach((value, key) => {
                if (value.associatedFieldName == 'Erscheinungsjahr') {
                    if (+value.name <= 0) {
                        return;
                    }
                }
                filterHolder.append(createFilterCheckbox(value));
            });

            value.elements.forEach((value, key) => {
                if (value.associatedFieldName == 'Erscheinungsjahr') {
                    if (+value.name <= 0) {
                        return;
                    }
                }
                filterHolder.append(createFilterCheckbox(value));
            });
            prototype.removeAttr('id');
            $('.ajax_filter_menu').append(prototype);
        }
    });
    $('.ajax_filter_link').on('click', function() {
        clickHandlerAjaxSearch(this);
    });
}

function createBoolFilter(value) {
    var trueElement = value.elements.filter(x => x.name.toLowerCase() === 'true')[0];
    var prototype = $('#protoypes #ajax_filter_boolElement').clone();
    prototype.find('.txt').html(value.name);
    $('.ajax_filter_menu').append(prototype);
    prototype.find('.ajax_filter_link').data('url', trueElement.searchParams);
    trueElement.selected ? prototype.find('.checkbox').addClass('selected') : '';
    prototype.removeAttr('id');
}

function createFilterCheckbox(filterGroupElements) {
    var prototype = $('#protoypes #ajax_filter_checkbox').clone();
    prototype.data('filtervalue', filterGroupElements.name);
    prototype.find('.ajax_filter_link').data('url', filterGroupElements.searchParams);
    filterGroupElements.selected ? prototype.find('.checkbox').addClass('selected') : '';
    prototype.find('.text').prepend(filterGroupElements.name);
    prototype.find('.matches').html('(' + filterGroupElements.recordCount + ')');
    prototype.removeAttr('id');
    return prototype;
}

function createFilterGroupMobile(filterGroups) {
    filterGroups.forEach((value, key) => {
        if (!nullOrEmpty(value.elements) && value.elements.filter(x => x.name.toLowerCase() === 'true')[0] != null) {
            createBoolFilterMobile(value);
        } else {
            var prototype = $('#protoypes #ajax_filter_element_mobile').clone();
            prototype
                .find('.filterCategory')
                .find('.txt')
                .html(value.name);
            prototype.find('.mobNavLink').attr('id', 'mobNavLink_' + value.name);
            var prevEl = prototype.find('.mobNavLink').find('.txt');
            $("<i class='fa fa-angle-right lvlDown' aria-hidden='true'></i>").insertAfter(prevEl);
            var filterHolder = prototype.find('.categorySubMenu');

            if (value.name !== 'Fachbereich' && value.name !== 'Lieferbarkeit' && value.name !== 'Preis') {
                let txtInput = $('#protoypes #ajax_filter_textinput').clone();
                $(txtInput).data('filtername', value.name);
                txtInput.removeAttr('id');
                filterHolder.append(txtInput);
            }

            value.elements.forEach((value, key) => {
                filterHolder.append(createFilterCheckboxMobile(value));
            });
            prototype.removeAttr('id');
            $('.ajax_filter_menu_mobile').append(prototype);
        }
    });
    $('.ajax_filter_link').on('click', function() {
        clickHandlerAjaxSearch(this);
    });
    $('.filterCategory').on('click', function() {
        lastClickedFilterParentId = $(this).attr('id');
    });
    $('.mobSubMenu .backLink').on('click', function() {
        lastClickedFilterParentId = '';
    });
}

function createBoolFilterMobile(value) {
    var trueElement = value.elements.filter(x => x.name.toLowerCase() === 'true')[0];
    var prototype = $('#protoypes #ajax_filter_boolElement_mobile').clone();
    prototype.find('.txt').html(value.name);
    $('.ajax_filter_menu_mobile').append(prototype);
    prototype.find('.ajax_filter_link').data('url', trueElement.searchParams);
    trueElement.selected ? prototype.find('.checkbox').addClass('selected') : '';
    prototype.removeAttr('id');
}

function createFilterCheckboxMobile(filterGroupElements) {
    var prototype = $('#protoypes #ajax_filter_checkbox_mobile').clone();
    prototype.data('filtervalue', filterGroupElements.name);
    prototype.find('.ajax_filter_link').data('url', filterGroupElements.searchParams);
    filterGroupElements.selected ? prototype.find('.checkbox').addClass('selected') : '';
    prototype.find('.text').prepend(filterGroupElements.name);
    prototype.find('.matches').html('(' + filterGroupElements.recordCount + ')');
    prototype.removeAttr('id');
    return prototype;
}

function createSearchResults(mainModel, searchResults) {
    searchResults.forEach((value, key) => {
        var result = value.record;
        var prototype = $('#ajax_filter_search_result').clone();
        var parent = $(prototype).find('.resultItem');
        var query = $('#sucheQuery').val() as any;
        var sessionId = $('#sessionId').val() as any;

        ///Set Data  for FactFinder Tracking
        parent.attr('data-id', value.id);
        parent.attr('data-origpos', result.__ORIG_POSITION__);
        parent.attr('data-pos', value.position);
        parent.attr('data-query', query);
        parent.attr('data-page', mainModel.paging.currentPage);
        parent.attr('data-pagesize', mainModel.paging.resultsPerPage);
        parent.attr('data-sessionid', sessionId);
        parent.attr('data-price', result.bruttopreis);
        parent.attr('data-simi', value.searchSimilarity);

        setLabels(prototype, result);
        prototype
            .find('.ajax_search_detail_link')
            .attr('href', result.produktURL ? result.produktURL : `/product/${result.productNumber}`);
        prototype
            .find('.ajax_search_result_img')
            .attr('src', result.produktAbbildung.replace('width=56', 'width=213&height=170'));
        if (result.urheberzeile != null && result.urheberzeile !== '') {
            prototype.find('.publisher').html(result.urheberzeile);
        } else {
            prototype.find('.publisher').remove();
        }

        prototype
            .find('.title')
            .find('.cutSpan')
            .html(result.haupttitel);
        prototype
            .find('.description')
            .find('.cutSpan')
            .html(getUntertitel(result));

        // Merkliste Link
        prototype.find('.merklisteProto').attr('data-id', value.id);
        if ($('#loggedInFlag').length > 0) {
            prototype.find('.merklisteProto').addClass('addToMerkliste');
            prototype
                .find('.merklisteProto')
                .children()
                .attr('data-id', value.id);
        } else {
            prototype.find('.merklisteProto').attr('href', `${$('#hiddenLoginUrl').val()}?merklisteId=${value.id}`);
        }

        //Bibliografische Daten
        if (result.darstellungsform != null && result.darstellungsform !== '') {
            createLinkSpan(prototype, result.darstellungsform);
        }
        if (result.medienname != null && result.medienname !== '' && result.medienname !== 'Buch') {
            createLinkSpan(prototype, result.medienname);
        }
        if (result.auflage != null && result.auflage !== '') {
            createLinkSpan(prototype, result.auflage);
            prototype.find('.edition').html(result.auflage);
        }

        if (result.erscheinungsjahr != null && result.erscheinungsjahr !== '' && +result.erscheinungsjahr > 0) {
            createLinkSpan(prototype, result.erscheinungsjahr);
        }

        if (result.verlag != null && result.verlag !== '') {
            createLinkSpan(prototype, result.verlag);
        }
        if (result.isbn) {
            createLinkSpan(prototype, `${result.isbN_ISSN_Type} ${result.isbn}`);
        }
        //Preis
        if (
            result.priceTypeId == 4000 &&
            result.priceNet == 0 &&
            (result.bruttopreis == '0' || result.bruttopreis == '0.00')
        ) {
            prototype
                .find('.pricing')
                .find('.price')
                .html('kostenloses Probe-Abo');
            prototype
                .find('.pricing')
                .find('.price')
                .css('font-family', 'LatoRegular, sans-serif');
            prototype
                .find('.pricing')
                .find('.ab')
                .remove();
            prototype
                .find('.pricing')
                .find('.ca')
                .remove();
        } else {
            if (result.preisvariantenMerkmal.toLowerCase() === 'true') {
                prototype
                    .find('.pricing')
                    .find('.ab')
                    .html('ab ');
            } else {
                prototype
                    .find('.pricing')
                    .find('.ab')
                    .remove();
            }
            if (result.caPreisMerkmal.toLowerCase() === 'true') {
                prototype
                    .find('.pricing')
                    .find('.ca')
                    .html('ca.');
            } else {
                prototype
                    .find('.pricing')
                    .find('.ca')
                    .remove();
            }
            if (
                result.caPreisMerkmal.toLowerCase() === 'false' &&
                result.preisvariantenMerkmal.toLowerCase() === 'false'
            ) {
                prototype
                    .find('.pricing')
                    .find('.ab')
                    .addClass('hide');
                prototype
                    .find('.pricing')
                    .find('.ca')
                    .addClass('hide');
            }
            var formattedPrice =
                result.bruttopreis !== null && result.bruttopreis !== ''
                    ? DelocaleString(parseFloat(result.bruttopreis), '.', 3, 2) + ' €'
                    : '';
            prototype
                .find('.pricing')
                .find('.price')
                .html(formattedPrice);
        }

        if (
            result.lngAvailabilityStatusID == 3 &&
            result.dateOfPublicationDescription != null &&
            result.dateOfPublicationDescription != ''
        ) {
            prototype
                .find('.availability')
                .html(result.lieferstatus + ' (' + result.dateOfPublicationDescription + ')');
            prototype
                .find('.availabiltyInfoMobile')
                .html(result.lieferstatus + ' (' + result.dateOfPublicationDescription + ')');
        } else {
            prototype.find('.availability').html(result.lieferstatus);
            prototype.find('.availabiltyInfoMobile').html(result.lieferstatus);
        }
        if (result.lngAvailabilityStatusID == 1 || result.lngAvailabilityStatusID == 4) {
            prototype.find('.availability').addClass('availabilityStatusColorGreen');
            prototype.find('.availabiltyInfoMobile').addClass('availabilityStatusColorGreen');
        }
        if (result.lngAvailabilityStatusID == 3 || result.lngAvailabilityStatusID == 5) {
            prototype.find('.openSendReminderModal').removeClass('hidden');
            prototype.find('.openSendReminderModal').attr('data-id', value.id);
        }

        //Warenkorb
        if (result.medienname == 'beck-online-Modul') {
            prototype
                .find('.beck-online-checkout-add')
                .attr('data-productid', value.id)
                .attr('data-price', result.bruttopreis.replace(',', '.'))
                .attr('href', buildBeckOnlineLink(result))
                .removeClass('hide');
        } else if (result.warenkorbsymbolAnzeigen === 'true' && result.darstellungsform !== 'Seminar') {
            let basketButton = prototype.find('.ajax_checkout_add');
            basketButton.attr('data-productid', value.id).removeClass('hide');
            basketButton.attr('data-pricetypeid', result.priceTypeId);

            if (ProductHelper.showBezugsartOverlay(result)) {
                basketButton
                    .removeClass('ajax_checkout_add')
                    .addClass('ajax_checkout_bezugsart')
                    .addClass('showModal')
                    .attr('data-toggle', 'modal')
                    .attr('data-target', '#modalBezugsart-01');
            }
        }

        if (result.darstellungsform == 'Seminar') {
            prototype.find('.merklisteProto').addClass('hide');
        }

        $('.ajax_search_results').append(prototype.html());
        $('img').unbind('error');
        $('img').on('error', function() {
            setDummyImage(this);
        });
    });
    $('.ajax_search_results .ajax_checkout_add').on('click', function() {
        var showModal = !$(this).hasClass('noModal');
        checkoutAdd($(this), showModal);
    });
}

function buildBeckOnlineLink(result) {
    return `https://shop.beck-online.de/?crop=${result.kupOrderNo}&chk=${result.kupOrderNo}&usr=1&cid=BS&uid=`;
}

function createLinkSpan(prototype, data) {
    var dataSpan = $('#protoypes')
        .find('#ElementsToClone')
        .find('span')
        .clone();
    data.trim();
    dataSpan.html(data);
    prototype.find('.links').append(dataSpan);
}

function createPagination(paging) {
    var prototype = $('#ajax_pagination').clone();
    //Pfeile
    if (paging.previousLink !== null && paging.previousLink.searchParams !== null) {
        var fullUrlCrawler = window.location.pathname + '?filter=' + window.btoa(paging.previousLink.searchParams);
        prototype.find('.btn.prev').attr('href', fullUrlCrawler);
        prototype.find('.btn.prev').data('url', paging.previousLink.searchParams);
    }
    if (paging.nextLink !== null && paging.nextLink.searchParams !== null) {
        var fullUrlCrawler = window.location.pathname + '?filter=' + window.btoa(paging.nextLink.searchParams);
        prototype.find('.btn.next').attr('href', fullUrlCrawler);
        prototype.find('.btn.next').data('url', paging.nextLink.searchParams);
    }
    //Nummern & Punkte
    if (paging.pageLinks[0].number > paging.firstLink.number) {
        var preDotsLi = $('#protoypes')
            .find('#ElementsToClone')
            .find('li')
            .clone();
        preDotsLi.html('...');
        prototype.find('ul').append(preDotsLi.html());
    }
    paging.pageLinks.forEach((value, key) => {
        var li = $('#protoypes')
            .find('#ElementsToClone')
            .find('li')
            .clone();
        var a = $('#protoypes')
            .find('#ElementsToClone')
            .find('a')
            .clone();
        var fullUrlCrawler = '';
        if (value.number === 1) {
            fullUrlCrawler = window.location.origin + window.location.pathname;
        } else {
            fullUrlCrawler =
                window.location.origin + window.location.pathname + '?filter=' + window.btoa(value.searchParams);
        }

        a.attr('href', fullUrlCrawler);
        a.data('url', value.searchParams);
        a.addClass('ajax_filter_link');
        a.html(value.number);
        if (value.number === paging.currentPage) {
            li.addClass('current');
            a.attr('aria-current', 'page');
        }
        li.append(a);
        prototype.find('ul').append(li);
    });
    if (paging.pageLinks[paging.pageLinks.length - 1].number < paging.lastLink.number) {
        var postDotsLi = $('#protoypes')
            .find('#ElementsToClone')
            .find('li')
            .clone();
        postDotsLi.html('...');
        prototype.find('ul').append(postDotsLi.html());
    }
    $('.ajax_pagination').append(prototype);

    //Click-Event
    $('.ajax_filter_link').on('click', function(e) {
        e.preventDefault();
        clickHandlerAjaxSearch(this);
    });
}

function createFilterTags(filterGroups) {
    filterGroups.forEach((value, key) => {
        value.elements.forEach((element, key) => {
            if (element.selected === true) {
                var prototype = $('#protoypes #ajax_mobile-filter-tag').clone();
                var span = $('#protoypes #ElementsToClone span').clone();
                span.html(' x ').addClass('closer');
                var displayName = element.name.toLowerCase() !== 'true' ? element.name : value.name;
                prototype.append(displayName).append(span);
                prototype.data('url', element.searchParams);
                prototype.removeAttr('id');
                $('.mobile-filter-tags').append(prototype);
            }
        });
        $('.ajax_filter_link_tag').on('click', function() {
            clickHandlerAjaxSearch(this);
        });
    });
}

function createSortList(sortList) {
    $('.ajax_sort_select').unbind('change');
    sortList.forEach((value, key) => {
        if (value.selected === true) {
            var prototype = $('#protoypes #ElementsToClone option').clone();
            if (value.description === 'sort.relevanceDescription') {
                prototype.html('Relevanz').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Haupttitel' && value.order === 'asc') {
                prototype.html('Titel').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Erscheinungsjahr' && value.order === 'desc') {
                prototype.html('Erscheinungsjahr').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Urheberzeile' && value.order === 'asc') {
                prototype.html('Autor').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
        }
    });
    sortList.forEach((value, key) => {
        if (value.selected === false) {
            var prototype = $('#protoypes #ElementsToClone option').clone();
            if (value.description === 'sort.relevanceDescription') {
                prototype.html('Relevanz').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Haupttitel' && value.order === 'asc') {
                prototype.html('Titel').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Erscheinungsjahr' && value.order === 'desc') {
                prototype.html('Erscheinungsjahr').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
            if (value.name === 'Urheberzeile' && value.order === 'asc') {
                prototype.html('Autor').data('url', value.searchParams);
                $('.ajax_sort_select').append(prototype);
            }
        }
    });
    $('.ajax_sort_select').on('change', function() {
        clickHandlerAjaxSearch(this);
    });
}

function setLabels(prototype, result) {
    if (result.neuerscheinungMerkmal.toLowerCase() !== 'true') {
        prototype.find('.statusLabel.Neuerscheinungsmerkmal').css('display', 'none');
    }
    if (result.voranzeigeMerkmal.toLowerCase() !== 'true') {
        prototype.find('.statusLabel.Vorankuendigungsmerkmal').css('display', 'none');
    }
    if (result.toptitelMerkmal.toLowerCase() !== 'true') {
        prototype.find('.statusLabel.Toptitelmerkmal').css('display', 'none');
    }
    if (!isStandardwerk(result)) {
        prototype.find('.statusLabel.Stadardwerkmerkmal').css('display', 'none');
    }
    if (!isEbook(result)) {
        prototype.find('.mediaTypeLabel.Ebookmerkmal').css('display', 'none');
    }
}

function isEbook(product) {
    return !nullOrEmpty(product.Medienname) && product.Medienname.match('^eBook');
}

function isStandardwerk(product) {
    return product.standardwerkMerkmal.toLowerCase() === 'true';
}

function getUntertitel(result) {
    if (!result.untertitelzusatz && !result.untertitel) {
        return '';
    }
    let untertitel = `${result.untertitelzusatz ? result.untertitelzusatz + ', ' : ''} ${result.untertitel}`.trim();

    if (untertitel.match(',$')) {
        return untertitel.substring(1, untertitel.length);
    }
    if (untertitel.slice(-1) === ',') {
        return untertitel.substring(0, untertitel.length - 1);
    }
    return untertitel;
}

function nullOrEmpty(element) {
    return element === null || element === '' || typeof element === 'undefined';
}
